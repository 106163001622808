
<template>
  <div class="row gy-2 mb-5 justify-content-center">
    <div class="col-md-10 col-lg-8">
      <h3 class="mb-4">File new claim</h3>
      <add-claim-form />
    </div>
  </div>
</template>

<script>
import AddClaimForm from "@/components/forms/AddClaimForm.vue"
export default {
  components:{
    AddClaimForm 
  },
  data(){
    return {
    }
  },
  created(){
    this.$store.dispatch("fetchItems")
  }
}

</script>

<style lang="scss">

</style>
