<template>
    <form autocomplete="off" class="add-claim-form">
        <div class="row g-4">
            <div class="col-md-12">
                <p class="mb-2">Select damage item</p>
                <select class="form-select" v-model="data.item_id"
                    name="item_id" placeholder="--select item--" 
                    :class="{ 'is-invalid': v$.data.item_id.$error}">
                    <option disabled value="">--select item--</option>
                    <option v-for="(item, cIndex) in items"
                        :key="cIndex" :value="item.id">{{item.title}}</option>
                </select>
                <div v-for="(item, index) in v$.data.item_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
             <div class="col-lg-6">
                <label for="loss_date">
                    Date of loss <span class="text-danger small">*</span>
                </label>
                <flat-pickr
                    v-model="data.loss_date"
                        :config="{wrap: true, 
                        altFormat: 'M j, Y',
                        altInput: true,
                        dateFormat: 'd M, Y',
                        maxDate: new Date()
                    }"
                    class="form-control flatpickr-input"
                    :class="{ 'is-invalid': v$.data.loss_date.$error }"
                ></flat-pickr>
                <div v-for="(item, index) in v$.data.loss_date.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-lg-6">
                <label class="form-label">Type of loss</label>
                <select class="form-select" v-model="data.loss_type"
                    name="loss_type" placeholder="--select type--"
                    :class="{ 'is-invalid': v$.data.loss_type.$error}">
                    <option disabled value="">--select type--</option>
                    <option value="CRACKED_SCREEN">Cracked Screen</option>
                    <option value="DROPS">Drops</option>
                    <option value="LIQUID_DAMAGE">Liquid Damage</option>
                    <option value="SCREEN_FAILURE">Screen failure</option>
                    <option value="PORT_FAILURE">Port failure</option>
                    <option value="BATTERY_FAILURE">Battery failure</option>
                    <option value="SPEAKER_FAILURE">Speaker failure</option>
                    <option value="CAMERA_FAILURE">Camera failure</option>
                </select>
                    <div v-for="(item, index) in v$.data.loss_type.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-12">
                <label class="form-label">
                    Description of Incident <span class="text-danger small">*</span>
                 </label>
                <textarea v-model="data.description" class="form-control" rows="4"
                    :class="{ 'is-invalid': v$.data.description.$error}"
                    placeholder="Write description" name="description"></textarea>
                <div v-for="(item, index) in v$.data.description.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>  
            <div class="col-12">
                <h5>Upload proof <span class="text-danger small">*</span></h5>
                <div class="bd-callout bd-callout-warning">
                    After submitting the form, kindly visit one of our partner's offices nearest to you to upload the necessary photo proofs.
                </div>
                <!-- <span>Provide clear images of damage, evidence, and/or a copy of your police report</span>
                <vue-dropzone id="incidentUploadDrop" ref="incidentUploadDrop"
                :use-custom-slot="true">
                    <div class="needsclick text-primary m-0">
                        <span class="ri ri-upload-cloud-2-line fs-1"></span>
                        <div> Upload evidence of damaged item</div>
                    </div>
                </vue-dropzone> -->
            </div>
            <div class="col-12">
                <label for="inputEmail">
                    Paypal Email
                </label>
                <input type="email" v-model="data.paypal_email"
                    :class="{ 'is-invalid': v$.data.paypal_email.$error }"
                    id="inputEmail" name="paypal_email" class="form-control"
                    placeholder="Enter paypal email" />
                <div v-for="(item, index) in v$.data.paypal_email.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
                <div class="form-check mt-2">
                    <input v-model="data.no_paypal_email" class="form-check-input" type="checkbox" value="" id="enablePaypalEmail">
                    <label class="form-check-label" for="enablePaypalEmail">
                        I don't have paypal email
                    </label>
                </div>
            </div>
            <div class="col-12">
                <label for="inputEmail">
                    Please type your full name as a digital signature
                </label>
                <input type="text" v-model="data.digital_signature"
                    :class="{ 'is-invalid': v$.data.digital_signature.$error }"
                    id="inputEmail" name="digital_signature" class="form-control"
                    placeholder="Your full name" />
                <div v-for="(item, index) in v$.data.digital_signature.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>                   
            <div class="col-12">
                <div class="d-flex mt-3">
                    <button @click="$router.go(-1)" class="btn btn-outline-primary me-2" type="button"> Cancel </button>
                    <button class="btn btn-primary" @click.prevent="create()" type="button">File Claim</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers, email, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
// import VueDropzone from "../VueDropzone.vue";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        flatPickr,
        // VueDropzone
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                item_id: "",
                loss_date: new Date(),
                loss_type: "",
                description: "",
                paypal_email: "",
                no_paypal_email: false,
                digital_signature: ""
            },
        }
    },
    validations() {
        return{
            data:{
                item_id: { required: helpers.withMessage("Select your item", required)},
                loss_date: {required: required},
                loss_type: {required: required},
                description: {required: required},
                paypal_email: {
                    requiredIf: requiredIf(!this.data.no_paypal_email),
                    email: helpers.withMessage("Please enter valid email", email)
                },
                digital_signature: { required: helpers.withMessage("Your full name is required", required)},
            }
        }
    },
    computed:{
        items(){
            return this.$store.state.items.filter(i => i.status == 'approved')
        },
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                //  if(!this.collectFiles().length){ this.alertError("Please an evidence is required"); return;}
                // this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/claims/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("ADD_CLAIM", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/claims/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("UPDATE_CLAIM", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        // collectFiles(){
        //     let arrafile = [];
        //     if(this.$refs.incidentUploadDrop){
        //         arrafile = [...this.$refs.incidentUploadDrop.getAcceptedFiles()];
        //     }
        //     return arrafile
        // },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
            this.$router.push({path: `/claims`})
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>


<style scoped>
.add-claim-form .item-item{
    border: 2px solid transparent;
}
.add-claim-form .item-item.active{
    border: 2px solid var(--bs-primary);
}
</style>